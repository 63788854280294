import React from 'react'
import Layout from '../components/Layout'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'

//Components
import Hero from '../components/Hero'

export default class ThankYouPage extends React.Component {
  render() {
    //Get Page Data
    const {data} = this.props

    // Parse Content for System Section
    return (<Layout>
      <div className="primary-contain">
        <Hero headerimg={data.pagecontent.frontmatter.image.childImageSharp.fluid}/>
        <div className="secondary-contain">
          <section className="columns">
            <div className="column is-8 is-offset-2 has-text-centered">
              <h1 className="title is-size-2 no-margin">Thank You</h1>
              <p className="bot-1">
                Thank you for requesting our leadership guide.  You should receive an email with the link shortly.  If you do not, please <a href="/contact"> Contact Us.</a>
              </p>
              <Link className="button is-primary" to="/">Back Home &rarr;</Link>
            </div>
          </section>
        </div>
      </div>
    </Layout>)
  }
}
export const pageQuery = graphql ` query ThankYouGuideQuery {

        pagecontent: markdownRemark(frontmatter : {
          path: {
            eq: "/contact"
          }
        }) {
          id
          frontmatter {
            path
            title
            cta
            image {
              childImageSharp {
                fluid(maxWidth : 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }
      `
